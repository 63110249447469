/*
 * TODO: Commented out code has been left here in case we wish to add functionality similar
 *       to the other test complete pages in the future.
 */

import React, { useState, useEffect, useContext } from "react";
import { FadeLoader } from "react-spinners";
import {
  // Button,
  Container,
  Row,
  Col,
  Card
  // Alert,
  // Form
  // Image
} from "react-bootstrap";
import { fetchTestEventData } from "./helpers";
import TestEventCompleted from "./TestDisplays/TestEventCompleted";
// import { apiURL } from "../utils/constants";
// import veryPoor from "../Resources/images/very_poor.png";
// import veryPoorActive from "../Resources/images/very_poor_active.png";
// import poor from "../Resources/images/poor.png";
// import poorActive from "../Resources/images/poor_active.png";
// import fair from "../Resources/images/fair.png";
// import fairActive from "../Resources/images/fair_active.png";
// import good from "../Resources/images/good.png";
// import goodActive from "../Resources/images/good_active.png";
// import excellent from "../Resources/images/excellent.png";
// import excellentActive from "../Resources/images/excellent_active.png";
// import checkCircle from "../Resources/images/check_circle.png";
// import useInterval from "../CustomHooks/useInterval";
import Header from "../Components/Header";
import useInterval from "../CustomHooks/useInterval";
import LandingPageParameters from "../Interfaces/LandingPageParameters";
import LandingPageStyle from "../Interfaces/LandingPageStyle";
import { TestEventContext } from "../Contexts/TestEventContext";

interface Props {
  path: string;
  eventId?: string;
  generateLandingPageStyle: (
    landingPageParameters: LandingPageParameters
  ) => LandingPageStyle;
}

const VideoInterviewTestEventCompleted = ({
  path,
  eventId,
  generateLandingPageStyle
}: Props) => {
  const context: any = useContext(TestEventContext);
  const [seconds, setSeconds] = useState(5);
  // const [numberOfAttempts, setNumberOfAttempts] = useState(2);
  // const [showURL, setShowURL] = useState(false);
  // const [ratingValue, setRating] = useState<number | null>(null);
  // const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [testEventData, setTestEventData] = useState<any>(undefined);
  const [landingPageStyle, setLandingPageStyle] =
    useState<LandingPageParameters | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [logo, setLogo] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!eventId) return;

    if (!context.isVideoInterviewDiscoveryJobCompleted) {
      fetchTestEventData(eventId).then((data: any) => {
        if (!data || data.hasOwnProperty("error")) {
          setLoading(false);
          return;
        }

        if (data.customExitPageType === "redirect") {
          setRedirectUrl(data.redirectUrl);
        } else if (data.returnUrl) {
          setRedirectUrl(data.returnUrl);
        }

        if (data.logo) {
          setLogo(data.logo);
        }
        setTestEventData(data);
        const landingPage: any = generateLandingPageStyle(data.landingPage);
        setLandingPageStyle(landingPage);
        setLoading(false);
      });
    } else {
      setTestEventData({
        isVideoInterviewDiscoveryJob: true,
        isVideoInterviewDiscoveryJobCompleted: true,
        videoInterviewDiscovery: context.videoInterviewDiscovery
      });
    }
  }, [eventId, generateLandingPageStyle, context]);

  useInterval(() => {
    const timeRemaining = seconds - 1;
    if (timeRemaining === 0 && redirectUrl) {
      const decodedURI = decodeURIComponent(`${redirectUrl}`);
      window.location.href =
        decodedURI.slice(0, 7) === "http://" ||
        decodedURI.slice(0, 8) === "https://"
          ? decodedURI
          : `https://${decodedURI}`;
    } else {
      setSeconds(timeRemaining);
    }
  }, 1000);

  // useEffect(() => {
  //   if (testEventData) {
  //     const landingPage: LandingPageParameters = testEventData.landingPage;
  //     setLandingPageStyle(generateLandingPageStyle(landingPage));
  //   }
  // }, [testEventData]);

  // const landingPageStyle = generateLandingPageStyle(testEventData.landingPage);

  // if (testEventData !== null && testEventData.optionalReturnUrl !== null) {
  //   redirectUrl = `${testEventData.optionalReturnUrl}`;
  // }

  // some fairly dense conditional here, but it is basically checking whether or not the redirectUrl needs to
  // contain the eventId and/or the orderId, and if so, determines where to get those values.
  // if (redirectUrl === null) {
  //   if (
  //     testEventData &&
  //     testEventData.exitPage.customExitPageType === "redirect"
  //   ) {
  //     redirectUrl = testEventData.exitPage.redirectUrl;
  //     if (
  //       testEventData &&
  //       testEventData.exitPage.includeEventId &&
  //       !testEventData.exitPage.includeOrderId
  //     ) {
  //       redirectUrl = `${redirectUrl}?event=${testEventData.eventId}`;
  //     }
  //     if (
  //       testEventData &&
  //       !testEventData.exitPage.includeEventId &&
  //       testEventData.exitPage.includeOrderId
  //     ) {
  //       redirectUrl = `${redirectUrl}?orderId=${
  //         testEventData.orderId !== null
  //           ? testEventData.orderId
  //           : testEventData.orderId
  //       }`;
  //     }
  //     if (
  //       testEventData &&
  //       testEventData.exitPage.includeEventId &&
  //       testEventData.exitPage.includeOrderId
  //     ) {
  //       redirectUrl = `${redirectUrl}?event=${testEventData.eventId}&orderId=${
  //         testEventData.orderId !== null
  //           ? testEventData.orderId
  //           : testEventData.orderId
  //       }`;
  //     }
  //   }
  // }
  // // on mount only, set the interval to count down the seconds, and clean up on unmount
  // useInterval(() => {
  //   const timeRemaining = seconds - 1;
  //   const attemptsRemaining = numberOfAttempts - 1;
  //   if (attemptsRemaining === 0) {
  //     setShowURL(true);
  //   } else {
  //     const decodedURI = decodeURIComponent(redirectUrl);
  //     if (timeRemaining === 0 && decodedURI && redirectUrl) {
  //       window.location.href =
  //         decodedURI.slice(0, 7) === "http://" ||
  //         decodedURI.slice(0, 8) === "https://"
  //           ? decodedURI
  //           : `https://${decodedURI}`;
  //       setNumberOfAttempts(attemptsRemaining);
  //     } else {
  //       setSeconds(timeRemaining);
  //     }
  //   }
  // }, 1000);

  // const handleRatingInput = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const ratingInputValue: number = Number(event.target.value);
  //   setRating(ratingInputValue);
  // };

  // interface FormData {
  //   testEventId: number;
  //   surveyQuestionId: number;
  //   response: number | null;
  // }

  // const handleSubmit = async (
  //   event: React.FormEvent<HTMLFormElement>,
  //   testEventData: any
  // ): Promise<void> => {
  //   event.preventDefault();

  //   const formData: FormData = {
  //     testEventId: testEventData.testEventId,
  //     surveyQuestionId: 1,
  //     response: ratingValue
  //   };

  //   try {
  //     const response = await fetch(`${apiURL}/surveyResponse`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json"
  //       },
  //       body: JSON.stringify(formData)
  //     });
  //     const responseData = await response;
  //     if (responseData.status === 200) {
  //       setFormSubmitted(true);
  //     }
  //   } catch (error) {
  //     console.log("Error occured when submitting survey: ", error);
  //     setFormSubmitted(true);
  //   }
  // };

  // const veryPoorImageToShow = ratingValue === 1 ? veryPoorActive : veryPoor;
  // const poorImageToShow = ratingValue === 2 ? poorActive : poor;
  // const fairImageToShow = ratingValue === 3 ? fairActive : fair;
  // const goodImageToShow = ratingValue === 4 ? goodActive : good;
  // const excellentImageToShow = ratingValue === 5 ? excellentActive : excellent;

  // const surveyQuestionJSON =
  //   testEventData && testEventData.odaSurveyQuestion
  //     ? JSON.parse(testEventData.odaSurveyQuestion.question)
  //     : null;

  // const ratingsList = [
  //   {
  //     id: 1,
  //     image: veryPoorImageToShow,
  //     text: surveyQuestionJSON.options[0],
  //     backgroundColor: "#ffceb1"
  //   },
  //   {
  //     id: 2,
  //     image: poorImageToShow,
  //     text: surveyQuestionJSON.options[1],
  //     backgroundColor: "#ffda99"
  //   },
  //   {
  //     id: 3,
  //     image: fairImageToShow,
  //     text: surveyQuestionJSON.options[2],
  //     backgroundColor: "#d7ebb3"
  //   },
  //   {
  //     id: 4,
  //     image: goodImageToShow,
  //     text: surveyQuestionJSON.options[3],
  //     backgroundColor: "#acebcd"
  //   },
  //   {
  //     id: 5,
  //     image: excellentImageToShow,
  //     text: surveyQuestionJSON.options[4],
  //     backgroundColor: "#abebed"
  //   }
  // ];

  // const ratingInputs = ratingsList.map(rating => {
  //   const textToShow =
  //     rating.id === ratingValue ? <strong>{rating.text}</strong> : rating.text;
  //   return (
  //     <td
  //       className="text-center"
  //       style={{
  //         width: "20%",
  //         verticalAlign: "top",
  //         border: 0,
  //         padding: 0,
  //         margin: 0
  //       }}
  //     >
  //       <Form.Check
  //         type="radio"
  //         id={`${rating.text}-radio`}
  //         style={{ padding: 0, margin: 0 }}
  //       >
  //         <Form.Check.Input
  //           value={rating.id}
  //           onChange={handleRatingInput}
  //           type="radio"
  //           style={{ opacity: "0" }}
  //           checked={rating.id === ratingValue}
  //         />
  //         <Form.Check.Label style={{ cursor: "pointer" }}>
  //           <div
  //             style={{
  //               margin: "auto",
  //               height: "56px",
  //               width: "56px",
  //               borderRadius: "50%",
  //               backgroundColor:
  //                 rating.id === ratingValue ? rating.backgroundColor : ""
  //             }}
  //           >
  //             <Image
  //               fluid={true}
  //               className="mb-2"
  //               src={rating.image}
  //               alt={rating.text}
  //               style={{ paddingTop: "8px" }}
  //             />
  //           </div>
  //           {textToShow}
  //         </Form.Check.Label>
  //       </Form.Check>
  //     </td>
  //   );
  // });
  return testEventData && !redirectUrl ? (
    <TestEventCompleted
      numberOfSecondsToDelay={5}
      incomingTestEventData={testEventData}
      fromRevelianEventComplete
    />
  ) : (
    <Container>
      <Row>
        <Col
          xl={{ span: 8, offset: 2 }}
          lg={{ span: 8, offset: 2 }}
          md={{ span: 10, offset: 1 }}
          sm={12}
        >
          {loading ? (
            <Row>
              <Col className="spinner-container">
                <div className="spinner text-center">
                  <FadeLoader
                    height={20}
                    width={20}
                    radius={20}
                    margin={30}
                    color="#B1B3B3"
                  />
                </div>
              </Col>
            </Row>
          ) : (
            <Card className="oda-card">
              <Header
                onWelcomePage={false}
                fromRevelianEventComplete={true}
                testEventData={testEventData}
                backgroundColor={landingPageStyle?.backgroundColor}
                customLogo={logo}
                isVideoInterviewDiscoveryJob={
                  testEventData?.isVideoInterviewDiscoveryJob
                }
              />
              <Card.Body>
                <React.Fragment>
                  {testEventData &&
                  testEventData.isVideoInterviewDiscoveryJob &&
                  testEventData.videoInterviewDiscovery ? (
                    <React.Fragment>
                      <p>
                        {testEventData.videoInterviewDiscovery?.completionMsg}
                      </p>
                      <p>
                        {
                          testEventData.videoInterviewDiscovery
                            ?.reviewSampleCandidates
                        }
                      </p>
                      <p className="text-center" style={{ fontWeight: "bold" }}>
                        <a
                          href={
                            testEventData.videoInterviewDiscovery.evaluationLink
                          }
                        >
                          {
                            testEventData.videoInterviewDiscovery
                              .beginEvaluation
                          }
                        </a>
                      </p>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Card.Title className="text-center mb-1">
                        Thank You.
                      </Card.Title>
                      <p>
                        You have completed the last assessment and your
                        responses have been successfully submitted.
                      </p>
                      <p>No further action is required.</p>
                    </React.Fragment>
                  )}
                  {redirectUrl ? (
                    <p
                      className={
                        context?.testEventData?.translatedText?.locale === "AR"
                          ? "text-right"
                          : ""
                      }
                    >
                      {context?.testEventData?.translatedText?.redirect
                        ? context.testEventData.translatedText.redirect.replace(
                            "{seconds}",
                            seconds
                          )
                        : `Please wait. You will be redirected in ${seconds} seconds...`}
                    </p>
                  ) : null}
                </React.Fragment>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default VideoInterviewTestEventCompleted;
