import { zonedTimeToUtc, formatInTimeZone } from "date-fns-tz";

export const getNewDate = (str: string): Date => {
  if (!str || typeof str !== "string") {
    return new Date(str);
  }
  return new Date(str.replace(/-/g, "/"));
};

const languageToLocaleLibrary: { [key: string]: string } = {
  spanish: "es",
  french: "fr",
  german: "de",
  portuguese: "pt",
  "portuguese-brazil": "pt-BR",
  turkish: "tr",
  "spanish-spain": "es",
  "french-canada": "fr-CA",
  chinese: "zh-CN",
  japanese: "ja",
  korean: "ko",
  italian: "it",
  czech: "cs",
  slovak: "sk",
  polish: "pl",
  arabic: "ar"
};

export const formatWithTranslation = async (
  date: Date,
  timezone: string,
  format: string,
  language: string
) => {
  const localeCode = languageToLocaleLibrary[language];
  let locale;

  try {
    if (localeCode) {
      locale = await import(`date-fns/locale/${localeCode}`);
    }
  } catch (error) {
    console.error(`Locale not supported: ${localeCode}`);
  }

  // fallback to English if the locale is not supported, or if the locale is not specified
  return formatInTimeZone(date, timezone, format, {
    locale: locale ? locale.default : undefined
  });
};

export const convertDateTimezone = async (
  date: string,
  timezone: string,
  isAULocale: boolean | null = false,
  language: string = "english"
): Promise<string> => {
  let formattedDate: string;

  // the eventEventExpirationDate is stored in the database in utc time so we need to convert it to a date
  const utcDateTime = zonedTimeToUtc(date, "UTC");

  // if the `timezone` value somehow is falsy
  // fallbacks to the old way of showing either AET or PT timezone based on the company's selected locale
  // otherwise it should be the timezone set in the company settings or the job settings
  const defaultDisplayTimeZone = isAULocale
    ? "Australia/Sydney"
    : "America/Los_Angeles";

  const displayedTimezone = timezone || defaultDisplayTimeZone;

  formattedDate = await formatWithTranslation(
    utcDateTime,
    displayedTimezone,
    "EEEE, MMMM d, yyyy @ h:mm a z",
    language
  );

  // Format the newly formatted date with custom timezone abbreviation if needed
  let customFormattedDateTimezoneAbbreviation: string =
    getCustomTimezoneWithNewAbbreviation(formattedDate, timezone);

  return customFormattedDateTimezoneAbbreviation;
};

export const formatTime = (time: number): string => {
  let roundedDownTime = Math.floor(time);
  let minutes: number | string = Math.floor(roundedDownTime / 60);
  let seconds: number | string = roundedDownTime - minutes * 60;
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return `${minutes}:${seconds}`;
};

export const shuffleArray = (a: any) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

// eslint-disable-next-line no-script-url
const blacklistedTerms = ["javascript:", "data:"];
export const validateCandidateInput = (
  input: string,
  requiredText: string,
  maxLength: number
) => {
  if (
    (requiredText && input.indexOf(requiredText) < 0) ||
    (maxLength && input.length > maxLength)
  ) {
    return false;
  } else if (
    blacklistedTerms.reduce(
      (bool: boolean | undefined, blacklistedTerm: string) => {
        return bool || input.indexOf(blacklistedTerm) > -1;
      },
      false
    )
  ) {
    return false;
  }
  return true;
};

export const encodeDangerousCharacters = (stringToEval: string) => {
  return stringToEval
    .split("")
    .reduce((encodedString: string, char: string) => {
      return `${encodedString}${char.replace(
        /[^a-z0-9 ]/i,
        encodeURIComponent(char)
      )}`;
    }, "");
};

const getExpirationDateUTCInMilliseconds = (expirationDate: string) => {
  const expirationDateUTC = zonedTimeToUtc(expirationDate, "UTC");
  const expirationDateUTCInMilliseconds = expirationDateUTC.getTime();

  return expirationDateUTCInMilliseconds;
};

export const isEventExpired = (expirationDate: string | null): boolean => {
  // if an event does not have an expiration time, then return false immediately
  if (expirationDate === null) {
    return false;
  }

  // get the current time in UTC milliseconds
  const currentUTCDateInMilliseconds = new Date().getTime();

  // get the expiration time in UTC milliseconds
  const expirationDateUTCInMilliseconds =
    getExpirationDateUTCInMilliseconds(expirationDate);

  // compare the two converted UTC millisecond values to determine if the event is expired or not.
  if (currentUTCDateInMilliseconds > expirationDateUTCInMilliseconds) {
    return true;
  }

  return false;
};

export const checkIsOneHourBeforeExpiration = (
  expirationDate: string | null
) => {
  let isOneHourBeforeExpiration: boolean = false;

  if (expirationDate !== null) {
    // get the current time in UTC milliseconds
    const currentUTCDateInMilliseconds = new Date().getTime();

    // get the expiration time in UTC milliseconds
    const expirationDateUTCInMilliseconds =
      getExpirationDateUTCInMilliseconds(expirationDate);

    const hour: number = 1000 * 60 * 60;

    isOneHourBeforeExpiration =
      expirationDateUTCInMilliseconds - currentUTCDateInMilliseconds > 0 &&
      expirationDateUTCInMilliseconds - currentUTCDateInMilliseconds < hour;
  }

  return isOneHourBeforeExpiration;
};

export const getBase64FromFile = (file: any) =>
  new Promise((resolve, reject) => {
    // this will return a promise, that can then be resolved with a function to send data to DB, or whatever else.
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const GDPRCountryValues = [
  "AT",
  "BE",
  "BG",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "SE"
];

export const preloadImages = async (questions: any) => {
  // eslint-disable-next-line array-callback-return
  questions.map((question: any, index: number) => {
    const img1 = new Image();
    img1.src = question.optionA;
    const img2 = new Image();
    img2.onload = () => {
      img2.src = question.optionB;
      if (index === 39) {
        return;
      }
    };
  });
};

export const isValidEmail = (emailAddress: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress);
};

export const getSubdomain = (): string => {
  const hostnameArray = window.location.hostname.split(".");
  const subdomain = hostnameArray[0].toUpperCase();

  return subdomain;
};

/*
 * The date-fns library does not have some of the timezone abbreviations. The best way to make
 * the code maintainable and scalable would be to create custom formatting for the abbreviations.
 * If a new city/timezone is added, we can simply check if there is an abbreviation for it and if not
 * then we apply the same format as below. The main timezone libraries do not have the listed timezones
 * with the correct abbreviation so until then we will need to do this.
 */
export const getCustomTimezoneWithNewAbbreviation = (
  formattedDate: string,
  timezone: string
) => {
  if (formattedDate.includes("UTC")) {
    formattedDate = formattedDate.replace("UTC", "GMT");
  }
  switch (timezone) {
    case "Pacific/Tahiti":
      return formattedDate.replace("GMT-10", "TAHT");
    case "America/Argentina/Buenos_Aires":
      return formattedDate.replace("GMT-3", "ART");
    case "America/Sao_Paulo":
      return formattedDate.replace("GMT-3", "BRT");
    case "Atlantic/Azores":
      if (formattedDate.includes("GMT-1")) {
        return formattedDate.replace("GMT-1", "AZOT");
      }
      return formattedDate.replace("GMT", "AZOST");
    case "Europe/Istanbul":
      return formattedDate.replace("GMT+3", "TRT");
    case "Asia/Dubai":
      return formattedDate.replace("GMT+4", "GST");
    case "Asia/Kabul":
      return formattedDate.replace("GMT+4:30", "AFT");
    case "Asia/Karachi":
      return formattedDate.replace("GMT+5", "PKT");
    case "Asia/Singapore":
      return formattedDate.replace("GMT+8", "SGT");
    case "Indian/Maldives":
      return formattedDate.replace("GMT+5", "MVT");
    case "Asia/Kathmandu":
      return formattedDate.replace("GMT+5:45", "NPT");
    case "Asia/Dhaka":
      return formattedDate.replace("GMT+6", "BST");
    case "Indian/Cocos":
      return formattedDate.replace("GMT+6:30", "CCT");
    case "Asia/Bangkok":
      return formattedDate.replace("GMT+7", "ICT");
    case "Asia/Jakarta":
      return formattedDate.replace("GMT+7", "WIB");
    case "Pacific/Nauru":
      return formattedDate.replace("GMT+12", "NRT");
    case "Australia/Sydney":
      if (formattedDate.includes("GMT+10")) {
        return formattedDate.replace("GMT+10", "AEST");
      }
      return formattedDate.replace("GMT+11", "AEDT");
    case "Pacific/Kiritimati":
      return formattedDate.replace("GMT+14", "LINT");
    case "Pacific/Pago_Pago":
      return formattedDate.replace("GMT-11", "SST");
    case "Europe/Berlin":
    case "Europe/Brussels":
    case "Europe/Madrid":
    case "Europe/Prague":
    case "Europe/Zurich":
    case "Europe/Paris":
      if (formattedDate.includes("GMT+2")) {
        return formattedDate.replace("GMT+2", "CEST");
      }
      return formattedDate.replace("GMT+1", "CET");
    case "Europe/Helsinki":
      if (formattedDate.includes("GMT+3")) {
        return formattedDate.replace("GMT+3", "EEST");
      }
      return formattedDate.replace("GMT+2", "EET");
    case "Asia/Calcutta":
      return formattedDate.replace("GMT+5:30", "IST");
    case "Asia/Hong_Kong":
      return formattedDate.replace("GMT+8", "HKT");
    case "Asia/Tokyo":
      return formattedDate.replace("GMT+9", "JST");
    case "Australia/Darwin":
    case "Australia/Adelaide":
      return formattedDate.replace("GMT+9:30", "ACST");
    case "Australia/Perth":
      return formattedDate.replace("GMT+8", "AWST");
    case "Australia/Brisbane":
      return formattedDate.replace("GMT+10", "AEST");
    case "Pacific/Auckland":
      if (formattedDate.includes("GMT+12")) {
        return formattedDate.replace("GMT+12", "NZST");
      }
      return formattedDate.replace("GMT+13", "NZDT");
    case "Europe/London":
      return formattedDate.replace("GMT+1", "BST");
    case "Africa/Lagos":
      return formattedDate.replace("GMT+1", "WAT");
    case "Asia/Shanghai":
      return formattedDate.replace("GMT+8", "CST");
    case "America/Bogota":
      return formattedDate.replace("GMT-5", "COT");
    case "America/Caracas":
      return formattedDate.replace("GMT-4", "VET");
    case "Asia/Seoul":
      return formattedDate.replace("GMT+9", "KST");
    default:
      return formattedDate;
  }
};

const englishTranslatedText = {
  warningText3:
    "For test security reasons, you cannot restart this event again and will need to contact our support team to have your access reset. They can be reached on the bottom right hand corner of ##URL##",
  connectionIssue:
    "For an optimal experience, please ensure your device has a reliable internet connection. If you experience an Internet connection issue and need to restart your assessment, you can go to ##URL## and enter in your Event ID.",
  testIsNonMobile:
    "You appear to be using a mobile device. This assessment is not currently supported on mobile browsers. Please take this assessment on a desktop computer. To take this test, go to ##URL## on a computer, and enter the following Event ID:{[EVENT_ID]}",
  nonMobileInstructionPage:
    "It looks like you are using a mobile device. This assessment is not currently supported on mobile browsers. Please take this assessment on a computer.\r\nTo perform this test, go to ##URL## on a computer and enter the following event ID: {[EVENT_ID]}",
  unsupportedBrowser:
    "This assessment is not currently supported on your browser. Please use Chrome, FireFox, or Safari to complete this assessment.\nTo take this assessment, go to ##URL## on a compatible browser, and enter the following Event ID.\nEVENT ID: {[EVENT_ID]}",
  errorBeginning:
    "There was an error beginning your assessment. Please forward the link you used to access the assessment to our support team. They can be reached on the bottom right hand corner of ##URL##"
};

export type RevelianErrorType =
  | "warningText3"
  | "connectionIssue"
  | "testIsNonMobile"
  | "nonMobileInstructionPage"
  | "unsupportedBrowser"
  | "errorBeginning"
  | null;

export const getTranslatedTextWithURL = (
  context: any,
  textKey: RevelianErrorType
): string => {
  if (textKey === null) {
    return "";
  }

  const stockText =
    typeof context?.testEventData?.translatedText?.[textKey] === "string"
      ? context.testEventData.translatedText[textKey]
      : englishTranslatedText[textKey];
  return stockText.replace(/##URL##/g, window.location.host);
};

export const getRedirectUrlForExitPage = (data: any) => {
  let url = "";
  if (
    data.error === "reused result" ||
    data.eventStatus === "completed" ||
    Object.keys(data).includes("reused")
  ) {
    if (data.returnUrl) {
      url = data.returnUrl;
    } else if (data.customExitPageType === "redirect") {
      url = data.redirectUrl;
    }
  }
  return url;
};

export const getReusedResultsCaseWithRawData = (data: any) => {
  if (
    data.error === "reused result" ||
    data.eventStatus === "completed" ||
    Object.keys(data).includes("reused")
  ) {
    if (data.error === "reused result" || data.reused) return 2;
    else return 1;
  }
  return 0;
};

export const getReusedResultsCaseWithDecryptedData = (
  decryptedData: any,
  data: any
) => {
  // if the testEvent is completed, or if the response returns error message
  // we need to check if the data or error message returns is reused or not
  if (
    decryptedData?.noMoreTest ||
    decryptedData?.reused ||
    data?.error === "reused result"
  ) {
    return 2;
  }

  if (Object.keys(decryptedData).includes("reused")) {
    return 3;
  }

  // Illustrait takes longer to score to be marked as event completed in the BE
  // if the testEvent contains Illustrait, we have to do a special check here
  // this is to direct the candidates to event complete page when the candidates have completed the testEvent on ODA and BE has not yet marked the testEvent as completed
  const hasIllustrait =
    decryptedData.tests && Object.keys(decryptedData.tests).indexOf("132") >= 0;

  const allTestsCompleted =
    decryptedData.tests &&
    Object.keys(decryptedData.tests).reduce((completed, testId) => {
      if (!decryptedData.tests[testId].completed) {
        return false;
      }
      return completed;
    }, true);

  // If VI comes after Illustrait, avoid reused results flow so candidate can proceed with VI
  const videoInterviewAfterAssessments =
    decryptedData.sortOrder && decryptedData.sortOrder[1] === "Video Interview";

  if (hasIllustrait && allTestsCompleted && !videoInterviewAfterAssessments) {
    return 1;
  }

  return 0;
};

export const phoneCountryCodes: { [key: string]: string } = {
  US: "+1",
  AZ: "+61",
  NZ: "+64"
};

// may need to handle other countries in the future but for now we can use this until then
export const formatPhoneNumber = (value: string, countryCode: string) => {
  // if the value is empty without the country code, return the empty string
  const phoneNumberWithoutCountryCode = value.replace(`${countryCode} `, "");

  if (!phoneNumberWithoutCountryCode) {
    return phoneNumberWithoutCountryCode;
  }

  const phoneNumber = phoneNumberWithoutCountryCode.replace(/[^.\d]/g, "");
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) {
    return `${countryCode} ${phoneNumber}`;
  }

  if (phoneNumberLength < 7) {
    return `${countryCode} (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3
    )}`;
  }

  return `${countryCode} (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

export const SESSION_DISCONNECTED_TEXT =
  "Your session has disconnected. To proceed from where you left off please reload the page and input your 16 digit event ID . If the issue continues, consider switching to a different web browser or device.";
