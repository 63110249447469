import React from "react";
import MaskedInput from "react-text-mask";
import { FadeLoader } from "react-spinners";
import { Container, Row, Col, Card, Alert, Form } from "react-bootstrap";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import PageContinueButton from "../Components/PageContinueButton";
import { isElevenDigit } from "./helpers";
import { TestEventConsumer } from "../Contexts/TestEventContext";

/* ----------- Interfaces ----------- */

interface Props {
  path: string;
  fromDisability: boolean;
  fromHelp: boolean;
  retrievingData: boolean;
  invalidEventId: boolean;
  errorMessage: string;
  invalidJobCode: boolean;
  invalidJobCodeMessage: string;
  default: boolean;
  fetchTestEventByEventId: (
    eventId: string,
    fromLink: boolean,
    fromExternalTest: boolean
  ) => Promise<boolean>;
}
interface State {
  eventId: string;
  disableGoButton: boolean;
  showModal: boolean;
  userInput: string;
}

export default class EventIdForm extends React.Component<Props, State> {
  state: State = {
    eventId: "",
    disableGoButton: true,
    showModal: false,
    userInput: ""
  };

  // define the context type so that we can use this.context throughout the class
  static contextType: any = TestEventConsumer;

  componentDidMount() {
    requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });
    if (this.props.fromDisability) {
      const disabilityButton = document.getElementById("disability-button");
      if (disabilityButton) {
        disabilityButton.click();
      }
    }
    if (this.props.fromHelp) {
      const helpButton = document.getElementById("help-button");
      if (helpButton) {
        helpButton.click();
      }
    }

    window.addEventListener("keyup", this.handleKeyUp);
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp);
  }

  /* ---------- Event Handlers ----------- */

  handleKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      const eventId: string = `${this.state.eventId}`;
      const formattedEventId: string = eventId.replace(/-/g, "");
      this.props.fetchTestEventByEventId(formattedEventId, false, false);
    }
  };

  handleEventIdInput = <T extends keyof State>(
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    // dynamically assign the id of the incoming input to a variable to be used throughout function
    const stateKey: string = event.currentTarget.id;

    // Set a new state object, with the new input data added
    const newState = { [stateKey]: event.currentTarget.value };

    // Add the new input data to state, and auto focus the next input appropriately, enabling the Go button
    // if all fields have the correct length
    this.setState(newState as { [P in T]: State[P] }, () => {
      if (this.state.eventId.includes("_")) {
        this.setState({ disableGoButton: true });
      } else {
        this.setState({ disableGoButton: false }, () => {
          const el: HTMLElement | null = document.getElementById("go-button");
          if (el) {
            el.focus();
          }
        });
      }
    });
  };
  handleEventIdSubmit = () => {
    // Compose the event id from the 3 individual pieces of state, and send to the event handler is App.tsx
    const eventId: string = `${this.state.eventId}`;
    const formattedEventId: string = eventId.replace(/-/g, "");
    this.props.fetchTestEventByEventId(formattedEventId, false, false);
  };

  //Event ID maybe 11-character or 16-character
  getMaskArray = (userInput: string) => {
    let userInputTrimmed = userInput.replace(/(-|_)/g, "");
    if (isElevenDigit(userInputTrimmed)) {
      return [
        /[A-Za-z]/,
        /[A-Za-z]/,
        /[A-Za-z]/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /[A-Za-z]/,
        /[A-Za-z]/,
        /[A-Za-z]/,
        /[A-Za-z]/
      ];
    }
    return [
      /[A-Za-z]/,
      /[A-Za-z]/,
      /[A-Za-z]/,
      "-",
      /^[0-9a-zA-Z]+$/,
      /^[0-9a-zA-Z]+$/,
      /^[0-9a-zA-Z]+$/,
      /^[0-9a-zA-Z]+$/,
      "-",
      /^[0-9a-zA-Z]+$/,
      /^[0-9a-zA-Z]+$/,
      /^[0-9a-zA-Z]+$/,
      /^[0-9a-zA-Z]+$/,
      "-",
      /^[0-9a-zA-Z]+$/,
      /^[0-9a-zA-Z]+$/,
      /^[0-9a-zA-Z]+$/,
      /^[0-9a-zA-Z]+$/,
      /^[0-9a-zA-Z]+$/
    ];
  };

  render() {
    if (!this.props.retrievingData) {
      return (
        <Container>
          <Row>
            <Col
              xl={{ span: 8, offset: 2 }}
              lg={{ span: 8, offset: 2 }}
              md={{ span: 10, offset: 1 }}
              sm={12}
            >
              <Card className="oda-card">
                <Header
                  onWelcomePage={false}
                  backgroundColor="white"
                  isVideoInterviewDiscoveryJob={
                    this.context?.isVideoInterviewDiscoveryJob
                  }
                />
                <Card.Body>
                  <Card.Title
                    as="h1"
                    className={
                      this.context?.testEventData?.translatedText?.locale ===
                      "AR"
                        ? "text-right h5"
                        : "text-center h5"
                    }
                  >
                    {this.context?.testEventData?.translatedText?.enterEventId
                      ? this.context.testEventData.translatedText.enterEventId
                      : "Enter Event ID"}
                  </Card.Title>
                  {this.props.invalidEventId ? (
                    <Alert variant="danger" className="text-center">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.errorMessage
                        }}
                      />
                    </Alert>
                  ) : null}
                  {this.props.invalidJobCode ? (
                    <Alert variant="danger" className="text-center">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.invalidJobCodeMessage
                        }}
                      />
                    </Alert>
                  ) : null}
                  <p
                    className={
                      this.context?.testEventData?.translatedText?.locale ===
                      "AR"
                        ? "text-right"
                        : ""
                    }
                  >
                    {this.context?.testEventData?.translatedText?.eventId1
                      ? this.context.testEventData.translatedText.eventId1
                      : "Enter your Event ID below. This will be an 11-character or 16-character ID. We recommend copying and pasting the Event ID into the field to avoid error."}
                  </p>
                  <Form.Group>
                    <Form.Label id="event-id-form-label" className="label">
                      {`${
                        this.context?.testEventData?.translatedText?.eventId
                          ? this.context.testEventData.translatedText.eventId
                          : "Event ID"
                      }: `}
                    </Form.Label>
                    <MaskedInput
                      mask={this.getMaskArray}
                      guide={true}
                      aria-labelledby="event-id-form-label"
                      type="text"
                      id="eventId"
                      onChange={this.handleEventIdInput}
                      disabled={this.props.invalidJobCode}
                      className="form-control"
                    />
                  </Form.Group>
                  <p
                    className={
                      this.context?.testEventData?.translatedText?.locale ===
                      "AR"
                        ? "text-right"
                        : ""
                    }
                  >
                    {this.context?.testEventData?.translatedText?.eventId2
                      ? this.context.testEventData.translatedText.eventId2
                      : "On the next screen, you will be prompted to enter or verify some personal information."}
                  </p>
                </Card.Body>
                <Card.Footer
                  style={{
                    borderTop: 0,
                    backgroundColor: "white"
                  }}
                >
                  <PageContinueButton
                    handler={this.handleEventIdSubmit}
                    disabled={this.state.disableGoButton}
                    buttonText={
                      this.context?.testEventData?.translatedText
                        ?.continueButton
                        ? this.context.testEventData.translatedText
                            .continueButton
                        : "Continue"
                    }
                  />
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <Footer />
        </Container>
      );
    } else {
      return (
        <Row>
          <Col className="spinner-container">
            <div className="spinner text-center">
              <FadeLoader height={15} color="#425CC7" />
            </div>
          </Col>
        </Row>
      );
    }
  }
}
