import React, { useContext } from "react";
import { Form } from "react-bootstrap";
import { TestEventContext } from "../Contexts/TestEventContext";

interface FieldProps {
  id: string;
  label?: string;
  fieldType?: string;
  addSelectOneOption?: boolean;
  options?: JSX.Element[];
  value?: any;
  maxLength?: number;
  handleInput: (
    event: React.SyntheticEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  disabled?: boolean;
  fieldRequired?: boolean;
  noColon?: boolean | null;
}

const Field = ({
  id,
  label,
  fieldType,
  addSelectOneOption,
  options,
  value,
  handleInput,
  maxLength,
  disabled,
  fieldRequired = false,
  noColon = false
}: FieldProps) => {
  const context = useContext(TestEventContext);
  return (
    <Form.Group>
      {label ? (
        <Form.Label htmlFor={id} className="label">
          {fieldRequired && (
            <>
              <i aria-hidden="true">*</i>
              <span className="sr-only">Required</span>
            </>
          )}
          {label}
          {label.slice(-1) === "?" || noColon ? "" : ":"}
        </Form.Label>
      ) : null}
      {fieldType!.toLowerCase() === "textbox" ? (
        <Form.Control
          id={id}
          type="text"
          value={value || ""}
          onChange={handleInput}
          maxLength={maxLength}
          required={fieldRequired}
        />
      ) : null}
      {fieldType!.toLowerCase() === "email" ? (
        <Form.Control
          id={id}
          type="email"
          value={value || ""}
          onChange={handleInput}
          maxLength={maxLength}
          disabled={disabled}
          required={fieldRequired}
        />
      ) : null}
      {fieldType!.toLowerCase() === "dropdown" ? (
        <Form.Control
          as="select"
          id={id}
          name={id}
          value={value || ""}
          onChange={handleInput}
          required={fieldRequired}
        >
          {addSelectOneOption ? (
            <option key={`selectOne-${id}`} value="">
              {context && context.testEventData.translatedText
                ? context.testEventData.translatedText.selectAnswer
                : "Select an answer"}
            </option>
          ) : null}
          {options ? options : null}
        </Form.Control>
      ) : null}
    </Form.Group>
  );
};

export default Field;
