/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { Redirect, navigate } from "@reach/router";
import { Container, Row, Col, Card, Alert, Button } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import "react-sweet-progress/lib/style.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { TestEventConsumer } from "../Contexts/TestEventContext";
import DocumentUploadCard from "../Components/DocumentUploadCard";

interface State {
  receivedResume: boolean;
  receivedOtherDocument: boolean;
  showValidationError: boolean;
  showInvalidTypeError: boolean;
  documentPreviouslyReceived: number;
  resumePreviouslyReceived: number;
  currentFile: any;
}
interface Props {
  path: string;
  docType?: string;
  documentId?: number;
}

export default class DocumentUpload extends Component<Props, State> {
  state: State = {
    receivedResume: false,
    receivedOtherDocument: false,
    showValidationError: false,
    showInvalidTypeError: false,
    documentPreviouslyReceived: 0,
    resumePreviouslyReceived: 0,
    currentFile: {}
  };

  // define the context type so that we can use this.context
  static contextType: any = TestEventConsumer;

  componentDidMount() {
    let currentFile;
    if (
      this.props.docType === "other_docs" &&
      this.props.documentId &&
      this.context.testEventData &&
      this.context.testEventData.documents
    ) {
      // determine which file is currently being collected, and set it in state.
      currentFile = this.context.testEventData.documents.files.filter(
        (file: any) => {
          return Number(file.documentId) === Number(this.props.documentId);
        }
      );

      // if returning to this page after already navigating through it, we want to see this flag so that
      // we can display the appropriate message.
      const documentPreviouslyReceived = currentFile[0].completed;

      this.setState({
        currentFile: currentFile[0],
        documentPreviouslyReceived
      });
    }

    if (this.props.docType === "resume") {
      // determine if we have already collected a resume (or previously declined that option)
      const resumePreviouslyReceived =
        this.context.testEventData.resumeComplete;

      this.setState({ resumePreviouslyReceived });
    }
  }

  /* ---------- Documents received methods ---------- */
  receivedResume = (value: boolean) => {
    this.setState({ receivedResume: value });
  };

  receivedOtherDocuments = (value: boolean) => {
    this.setState({ receivedOtherDocument: value });
  };

  clearValidationError = () => {
    this.setState({
      showValidationError: false
    });
  };

  updateInvalidTypeError = (value: boolean): void => {
    this.setState({ showInvalidTypeError: value });
  };

  /* ---------- Validation Method ----------- */
  receivedRequiredResume = () => {
    // set validation variable
    let validated: boolean = true;

    // check for resume, if required
    if (
      this.context.testEventData.requireResume === 1 &&
      !this.state.receivedResume
    ) {
      validated = false;
    }

    return validated;
  };

  determineIfRequiredDocs = () => {
    if (
      (this.props.docType === "resume" &&
        this.context.testEventData.requireResume === 1) ||
      ((this.context.testEventData.requireResume === 0 ||
        this.context.testEventData.requireResume === 2) &&
        this.state.receivedResume)
    ) {
      return true;
    }
    if (
      this.state.currentFile.required === 1 ||
      (this.state.currentFile.required === 0 &&
        this.state.receivedOtherDocument)
    ) {
      return true;
    }
    return false;
  };

  receivedRequiredDocuments = () => {
    // set validation variable to function scope
    let validated: boolean = true;
    // if the file is required, but nothing has been received, set the validated variable to false
    if (this.state.currentFile.required && !this.state.receivedOtherDocument) {
      validated = false;
    }
    return validated;
  };

  /* ---------- Event Handlers ---------- */

  handleProceed = () => {
    if (this.props.docType === "resume") {
      if (this.receivedRequiredResume()) {
        // if there are no "other documents" to collect, and no test batteries in the event, we
        // want to navigate to the tests route to close out the event.  If there are, navigate
        // back to the overview page.
        if (
          !this.context.testEventData.documents &&
          this.context.testEventData.tests === null &&
          !this.context.videoInterview
        ) {
          navigate("/tests");
        } else {
          this.context.updateResumeSubmitted(1);
          navigate("/overview");
        }
      } else {
        this.setState({ showValidationError: true });
      }
    } else {
      if (this.receivedRequiredDocuments()) {
        this.context.updateOtherDocsSubmitted(
          this.state.currentFile.documentId,
          true
        );
        // if there are no test batteries in the event, we want to navigate to the tests route to
        // close out the event.  If there are, navigate  back to the overview page.
        if (
          !this.context.testEventData.documents &&
          this.context.testEventData.tests === null
        ) {
          navigate("/tests");
        } else {
          navigate("/overview");
        }
      } else {
        this.setState({ showValidationError: true });
      }
    }
  };
  render() {
    const isArabic = this.context?.testEventData?.translatedText?.locale
      ? this.context.testEventData.translatedText?.locale === "AR"
      : null;

    return (
      <TestEventConsumer>
        {context => {
          if (context && Object.keys(context.testEventData).length > 0) {
            const landingPageStyle = context.generateLandingPageStyle(
              context.testEventData.landingPage
            );
            return (
              <Container>
                <Row>
                  <Col
                    xl={{ span: 8, offset: 2 }}
                    lg={{ span: 8, offset: 2 }}
                    md={{ span: 10, offset: 1 }}
                    sm={12}
                  >
                    <Card>
                      <Header
                        onWelcomePage={false}
                        backgroundColor={landingPageStyle.backgroundColor}
                        customLogo={context?.testEventData.logo}
                      />
                      <Card.Body
                        style={{
                          backgroundColor: landingPageStyle.backgroundColor,
                          color: landingPageStyle.textColor
                        }}
                        className={isArabic ? "text-right" : "text-center"}
                        dir={isArabic ? "rtl" : "ltr"}
                      >
                        {this.state.showValidationError ? (
                          <Alert variant="danger">
                            {context.testEventData?.translatedText
                              ?.requiredUploads
                              ? context.testEventData.translatedText
                                  .requiredUploads
                              : "Please upload all required documents."}
                          </Alert>
                        ) : null}

                        {this.state.showInvalidTypeError ? (
                          <Alert variant="danger">
                            {context.testEventData?.translatedText
                              ?.invalidDocumentType
                              ? context.testEventData.translatedText
                                  .invalidDocumentType
                              : "That document format is invalid. Please upload one of the accepted formats."}
                          </Alert>
                        ) : null}

                        {this.state.documentPreviouslyReceived ||
                        this.state.resumePreviouslyReceived ? (
                          <Alert variant="warning">
                            {context.testEventData?.translatedText
                              ?.alreadyUploaded
                              ? context.testEventData.translatedText
                                  .alreadyUploaded
                              : "You have already uploaded a file. Uploading another file will overwrite your previously uploaded file."}
                          </Alert>
                        ) : null}
                        {this.props.docType === "resume" ? (
                          <DocumentUploadCard
                            title={
                              context.testEventData.translatedText
                                ? context.testEventData.translatedText.resume
                                : "Resume Upload"
                            }
                            instructions={
                              context.testEventData.translatedText.uploadMsg
                            }
                            isResume={true}
                            receivedResume={this.receivedResume}
                            clearValidationError={this.clearValidationError}
                            updateInvalidTypeError={this.updateInvalidTypeError}
                          />
                        ) : null}
                        {this.props.docType === "other_docs" ? (
                          <DocumentUploadCard
                            title={this.state.currentFile.name}
                            instructions={
                              this.context.testEventData.documents.instructions
                            }
                            isResume={false}
                            receivedResume={this.receivedResume}
                            file={this.state.currentFile}
                            receivedOtherDocuments={this.receivedOtherDocuments}
                            clearValidationError={this.clearValidationError}
                            updateInvalidTypeError={this.updateInvalidTypeError}
                          />
                        ) : null}
                      </Card.Body>
                      <Card.Footer
                        style={{
                          borderTop: 0,
                          backgroundColor: landingPageStyle.backgroundColor
                        }}
                      >
                        <Row className="mt-3 mb-2 text-center">
                          <Col
                            xl={{ span: 6, offset: 3 }}
                            lg={{ span: 6, offset: 3 }}
                            md={{ span: 8, offset: 2 }}
                            sm={12}
                          >
                            <Button
                              block={true}
                              variant="primary"
                              id="event-id-continue-button"
                              onClick={this.handleProceed}
                              style={{ height: "40px" }}
                            >
                              {this.determineIfRequiredDocs()
                                ? `${this.context.testEventData.translatedText.continueButton} `
                                : `${context.testEventData.translatedText.skipUpload} `}
                              <FaArrowRight />
                            </Button>
                          </Col>
                        </Row>
                      </Card.Footer>
                    </Card>
                  </Col>
                </Row>
                <Footer />
              </Container>
            );
          }
          // If the user has arrived here without any way of fetching the testEventData, redirect back to the
          // eventIdForm
          return <Redirect from="document_upload" to="/" noThrow={true} />;
        }}
      </TestEventConsumer>
    );
  }
}
