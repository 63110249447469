import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { TestEventContext } from "../Contexts/TestEventContext";
import TestHeader from "../Tests/TestDisplays/TestHeader";
import { getTranslatedTextWithURL } from "../utils/shared";

interface Props {
  path: string;
}

const UnsupportedBrowser = ({ path }: Props) => {
  const context = useContext(TestEventContext);
  const testData: any | null =
    context &&
    context.testIdsArray !== null &&
    context.testIdsArray[context.testIndex]
      ? context.testEventData.tests[context.testIdsArray[context.testIndex]]
          .details
      : null;

  const instructionString = getTranslatedTextWithURL(
    context,
    "unsupportedBrowser"
  ).replace("{[EVENT_ID]}", String(context?.eventId));

  return (
    <div
      style={{
        minHeight: "100vh",
        height: "100%",
        backgroundColor: "#FFF",
        margin: "-16px",
        padding: "16px"
      }}
    >
      <Container>
        <Row>
          <Col
            xl={{ span: 8, offset: 2 }}
            lg={{ span: 8, offset: 2 }}
            md={{ span: 10, offset: 1 }}
            sm={12}
          >
            <div id="instructions">
              <React.Fragment>
                {testData !== null ? (
                  <div dir={testData.direction ? testData.direction : "ltr"}>
                    <TestHeader
                      testTitle={testData.title}
                      testType={testData.testType}
                    />
                    <div id="instructions-text">
                      {context && context.testEventData.translatedText ? (
                        <p
                          className={
                            context &&
                            context?.testEventData?.translatedText?.locale ===
                              "AR"
                              ? "text-right"
                              : ""
                          }
                          dangerouslySetInnerHTML={{
                            __html: instructionString
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </React.Fragment>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UnsupportedBrowser;
