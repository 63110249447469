/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { Container, Row, Col, Card, Alert, Button } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import { TestEventConsumer } from "../Contexts/TestEventContext";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Redirect, navigate } from "@reach/router";
import ApplicationForm from "./ApplicationForm";
import ApplicationQuestions from "../Interfaces/ApplicationQuestions";
import { sendAnswers } from "./apiFunctions";
import { apiURL } from "../utils/constants";
import { getRedirectUrl } from "./helpers";

interface State {
  applicationIndex: number;
  answerObject: any;
  missingQuestions: string[];
  disqualified: boolean;
  disaqualifiedMessage: string;
  errorMessage: string;
  redirectUrl: string | null;
  seconds: number;
  applicationSubmitted: boolean;
}

interface Props {
  path: string;
  setUserDisqualified: (value: boolean) => void;
  numberOfSecondsToDelay: number;
}

export default class Applications extends Component<Props, State> {
  state = {
    applicationIndex: 0,
    answerObject: {} as any,
    missingQuestions: [],
    disqualified: false,
    disaqualifiedMessage: "",
    errorMessage: "",
    redirectUrl: "",
    seconds: this.props.numberOfSecondsToDelay || 5,
    applicationSubmitted: false
  };

  // define the context type so that we can use this.context throughout the class
  static contextType: any = TestEventConsumer;

  /* ---------- Event Handlers ----------- */

  handleInput = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    // set event properties to variables so that they persist
    const id = event.target.id;
    const value = event.target.value;

    // copy existing answerObject
    const newanswerObject: any = this.state.answerObject;

    if (value === "") {
      delete newanswerObject[id];
    } else {
      // replace any parentheses with their HTML entity codes so that AWS WAF doesnt block the request
      // the api will handle converting them back to parentheses
      const encodedAnswer = value
        .replace(/\(/g, "&#40;")
        .replace(/\)/g, "&#41;");

      // add new answer to the existing ones
      newanswerObject[id] = encodedAnswer;
    }

    this.setState({ answerObject: newanswerObject });
  };

  handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // set event properties to variables so that they persist
    const name = event.target.name;
    const value = event.target.value;

    // copy existing answerObject
    const newanswerObject: any = this.state.answerObject;

    if (value === "") {
      delete newanswerObject[name];
    } else {
      // add new answer to the existing ones
      newanswerObject[name] = value;
    }

    this.setState({ answerObject: newanswerObject });
  };

  handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    // set event properties to variables so that they persist
    const id = event.currentTarget.id;
    const value = event.currentTarget.value;

    // copy existing answerObject
    const newAnswerObject: any = this.state.answerObject;

    if (newAnswerObject[id]) {
      const clonedAnswerArray = newAnswerObject[id];
      if (clonedAnswerArray.includes(value)) {
        const filteredAnswerArray: any = clonedAnswerArray.filter(
          (answer: any) => answer !== value
        );
        if (filteredAnswerArray.length === 0) {
          delete newAnswerObject[id];
        } else {
          newAnswerObject[id] = filteredAnswerArray;
        }
      } else {
        newAnswerObject[id] = [...clonedAnswerArray, value];
      }
      this.setState({ answerObject: newAnswerObject });
    } else {
      newAnswerObject[id] = [event.currentTarget.value];
      this.setState({ answerObject: newAnswerObject });
    }
  };

  handleSubmit = () => {
    const formData: any = this.state.answerObject;
    formData.applicationId =
      this.context.applicationIdsArray[this.state.applicationIndex];
    formData.testEventId = this.context.testEventId;
    formData.testTakerId = this.context.testEventData.testTaker.testTakerId;

    this.validateApplication(formData);
  };

  /* ----------- End Test Event method ------------ */

  endTestEvent = async (message: string) => {
    const testEventData = {
      testEventId: this.context.testEventId
    };
    try {
      const response = await fetch(`${apiURL}/complete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.context.token
        },
        body: JSON.stringify(testEventData)
      });

      if (response.ok) {
        const redirectUrl = getRedirectUrl(this.context);
        if (redirectUrl) {
          this.setState(
            {
              disqualified: true,
              disaqualifiedMessage: message,
              redirectUrl
            },
            () => {
              this.props.setUserDisqualified(true);
              const redirectInterval = setInterval(() => {
                const timeRemaining = this.state.seconds - 1;

                const decodedURI = decodeURIComponent(redirectUrl);
                if (timeRemaining === 0 && decodedURI && redirectUrl) {
                  clearInterval(redirectInterval);
                  window.location.href =
                    decodedURI.slice(0, 7) === "http://" ||
                    decodedURI.slice(0, 8) === "https://"
                      ? decodedURI
                      : `https://${decodedURI}`;
                } else {
                  this.setState({ seconds: timeRemaining });
                }
              }, 1000);
            }
          );
        } else {
          this.setState({
            disqualified: true,
            disaqualifiedMessage: message,
            redirectUrl
          });
          this.props.setUserDisqualified(true);
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
    return true;
  };

  /* ----------- Validation Method ----------- */

  validateApplication = (formData: any) => {
    // define the currentApplication being submitted
    const currentApplicationId =
      this.context.applicationIdsArray[this.state.applicationIndex];
    const applicationData: any[] = [];
    if (
      this.context.applicationIdsArray !== null &&
      this.context.applicationIdsArray &&
      this.context.testEventData.application
    ) {
      this.context.testEventData.application.map((app: any, index: string) => {
        if (Object.keys(app)[0] === currentApplicationId) {
          applicationData.push(app[Object.keys(app)[0]]);
        }
      });
    }
    // create an array of required questions ids
    const requiredQuestions: string[] = [];

    // determine if the question has an answer, or, if it's a linked question, where the linked question has an answer
    applicationData[0].questions.map((question: ApplicationQuestions) => {
      if (question.required === 1) {
        requiredQuestions.push(`q_${question.applicationQuestionId}`);
      }
      if (question.required === 2) {
        const linkedApplicationQuestionId: string =
          applicationData[0].appQuestionsArray[question.linkedQuestionId];
        const { linkedQuestionOperator, linkedQuestionValue } = question;

        const linkedQuestionAnswer =
          this.state.answerObject[`q_${linkedApplicationQuestionId}`];
        switch (linkedQuestionOperator) {
          case "equals":
            if (linkedQuestionAnswer === linkedQuestionValue) {
              requiredQuestions.push(`q_${question.applicationQuestionId}`);
            }
            break;
          case "does not equal":
            if (
              linkedQuestionAnswer !== undefined &&
              linkedQuestionAnswer !== linkedQuestionValue
            ) {
              requiredQuestions.push(`q_${question.applicationQuestionId}`);
            }
            break;
        }
      }
    });

    // iterate through the requiredQuestions array and check to see if that key exists in the answerObject in state.
    // if it does not exist there, push that question id to the missingQuestions array in state.
    const missingQuestions: string[] = [];
    const answeredQuestionsArray = Object.keys(this.state.answerObject);
    requiredQuestions.map(id => {
      if (!answeredQuestionsArray.includes(id)) {
        requestAnimationFrame(() => {
          window.scrollTo(0, 0);
        });
        missingQuestions.push(id);
      }
    });
    this.setState({ missingQuestions }, () => {
      if (this.state.missingQuestions.length === 0) {
        this.setState({ applicationSubmitted: true });

        const applicationAnswers = sendAnswers(formData);
        applicationAnswers
          .then((response: any) => {
            if (response.error) {
              this.setState({
                errorMessage: this.context.testEventData?.translatedText
                  ?.applicationError
                  ? this.context.testEventData.translatedText.applicationError
                  : "There was an error in recording your answers."
              });
            } else if (response && response.message) {
              this.endTestEvent(response.message);
            } else {
              this.context.updateCompletedApplications(
                formData.applicationId,
                this.context.testEventData.application
              );
            }
            this.setState({ applicationSubmitted: false });
          })
          .catch(error => {
            this.setState({
              errorMessage: this.context.testEventData?.translatedText
                ?.applicationError
                ? this.context.testEventData.translatedText.applicationError
                : "There was an error in recording your answers.",
              applicationSubmitted: false
            });
          });
      }
    });
  };

  render() {
    const currentApplicationId =
      this.context.applicationIdsArray[this.state.applicationIndex];
    const applicationData: any[] = [];
    if (
      this.context.applicationIdsArray !== null &&
      this.context.applicationIdsArray &&
      this.context.testEventData.application
    ) {
      this.context.testEventData.application.map((app: any, index: string) => {
        if (Object.keys(app)[0] === currentApplicationId) {
          applicationData.push(app[Object.keys(app)[0]]);
        }
      });
    }

    // create an object containing all of the event handlers to make passing them through props easier
    const eventHandlerObject = {
      inputHandler: this.handleInput,
      selectHandler: this.handleSelect,
      handleCheckbox: this.handleCheckbox
    };

    const isArabic = this.context?.testEventData?.translatedText?.locale
      ? this.context.testEventData.translatedText?.locale === "AR"
      : null;

    return (
      <TestEventConsumer>
        {context => {
          if (
            context &&
            Object.keys(context.testEventData).length > 0 &&
            applicationData &&
            applicationData.length > 0
          ) {
            const landingPageStyle = context.generateLandingPageStyle(
              context.testEventData.landingPage
            );
            const { applicationName, applicationInstructions } =
              applicationData[0].appDetail;
            return (
              <Container>
                <Row>
                  <Col
                    xl={{ span: 8, offset: 2 }}
                    lg={{ span: 8, offset: 2 }}
                    md={{ span: 10, offset: 1 }}
                    sm={12}
                  >
                    <Card>
                      <Header
                        onWelcomePage={false}
                        backgroundColor={landingPageStyle.backgroundColor}
                        customLogo={context?.testEventData.logo}
                      />
                      <Card.Body
                        style={{
                          backgroundColor: landingPageStyle.backgroundColor,
                          color: landingPageStyle.textColor
                        }}
                        className={isArabic ? "text-right" : ""}
                      >
                        {!this.state.disqualified ? (
                          <div>
                            <Card.Title className="text-center">
                              {applicationName}
                            </Card.Title>
                            {applicationInstructions ? (
                              <div className="pt-2 pb-4">
                                {applicationInstructions}
                              </div>
                            ) : null}
                            <ApplicationForm
                              questions={applicationData[0].questions}
                              eventHandlerObject={eventHandlerObject}
                              missingQuestions={this.state.missingQuestions}
                              isArabic={isArabic}
                            />
                            {this.state.errorMessage ? (
                              <Alert
                                variant="danger"
                                className={
                                  isArabic ? "text-right" : "text-center"
                                }
                              >
                                {this.state.errorMessage}
                              </Alert>
                            ) : null}
                            <div className="text-center pt-4">
                              <Button
                                block={true}
                                variant="primary"
                                className="button-primary-outlined"
                                disabled={this.state.applicationSubmitted}
                                onClick={this.handleSubmit}
                              >
                                {
                                  this.context.testEventData.translatedText
                                    .submitApplication
                                }{" "}
                                <FaArrowRight />
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <h2>Thank You</h2>
                            <p>{this.state.disaqualifiedMessage}</p>
                            {this.context &&
                            this.context.testEventData &&
                            this.context.testEventData.exitPage.redirectUrl ? (
                              <p
                                className={
                                  this.context?.testEventData?.translatedText
                                    ?.locale === "AR"
                                    ? "text-right"
                                    : ""
                                }
                              >
                                {this.context.testEventData?.translatedText
                                  ?.redirect
                                  ? this.context.testEventData.translatedText.redirect.replace(
                                      "{seconds}",
                                      this.state.seconds
                                    )
                                  : `Please wait. You will be redirected in ${this.state.seconds} seconds...`}
                              </p>
                            ) : null}
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Footer />
              </Container>
            );
          } else if (
            applicationData &&
            applicationData.length === 0 &&
            this.context.eventId !== "" &&
            this.context.videoInterview === null &&
            this.context.testEventData.tests === null &&
            !this.context.testEventData.requireResume &&
            !this.context.testEventData.documents
          ) {
            // if there are no tests in the test event, and not collecting any other materials, and no video interviews, we want to navigate to the tests
            // route so that it will finish out the event.
            navigate("/tests");
          } else if (
            applicationData &&
            applicationData.length === 0 &&
            this.context.eventId !== ""
          ) {
            navigate("/overview");
          } else {
            // If the user has arrived here without any way of fetching the testEventData, redirect back to the
            // eventIdForm
            return <Redirect from="application" to="/" noThrow={true} />;
          }
        }}
      </TestEventConsumer>
    );
  }
}
