import Language from "../../Interfaces/Language";

export const ar: Language = {
  common: {
    help: "Need Help?",
    helpCenter: "Help Centre",
    helpCenterLink: "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/",
    expect: "What to expect on these tests",
    expectLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/collections/3396642-about-criteria-assessments",
    faq: "Frequently Asked Questions",
    faqLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7857232-%D8%A7%D9%84%D8%A3%D8%B3%D8%A6%D9%84%D8%A9-%D8%A7%D9%84%D8%B4%D8%A7%D8%A6%D8%B9%D8%A9",
    copyright: "حقوق الطبع والنشر محفوظة © 2024 لشركة Criteria Corp.",
    copyrightNotices: "إشعارات حقوق النشر",
    copyrightNoticesLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994368-%D8%A5%D8%B4%D8%B9%D8%A7%D8%B1%D8%A7%D8%AA-%D8%AD%D9%82%D9%88%D9%82-%D8%A7%D9%84%D9%86%D8%B4%D8%B1",
    terms: "شروط الاستخدام",
    termsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994301-%D8%B4%D8%B1%D9%88%D8%B7-%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%AE%D8%AF%D8%A7%D9%85",
    privacy: "بيان الخصوصية",
    privacyLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6990030-سياسة-الخصوصية",
    dmca: "DMCA Policy",
    dmcaLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7857186-%D8%B3%D9%8A%D8%A7%D8%B3%D8%A9-%D9%82%D8%A7%D9%86%D9%88%D9%86-%D8%A7%D9%84%D8%A3%D9%84%D9%81%D9%8A%D8%A9-%D9%84%D8%AD%D9%82%D9%88%D9%82-%D8%B7%D8%A8%D8%B9-%D9%88%D9%86%D8%B4%D8%B1-%D8%A7%D9%84%D9%85%D9%88%D8%A7%D8%AF-%D8%A7%D9%84%D8%B1%D9%82%D9%85%D9%8A%D8%A9-dmca",
    accommodations: "الإعاقة / الترتيبات التيسيرية",
    accommodationsLink:
      "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994435-???????-?????????-?????????",
    technicalSupport: "Technical Support",
    chatSupport:
      "For support, please chat with our team on the bottom right hand corner of this page"
  },
  overview: {
    approx: "تقريبًا",
    minutes: "دقيقة/دقائق"
  },
  instruction: {
    eppCopyright:
      "يتضمن هذا التقييم موادًا أُعيد إنتاجها من SalesAP ©1995 وCSAP ©2002, Multi-Health Systems Inc.. جميع الحقوق محفوظة.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA autorské práva © 1997, 1999 Psychological Assessment Resources, Inc."
  }
};
