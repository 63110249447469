import React, { useContext, useEffect, useState, useCallback } from "react";
import { FadeLoader } from "react-spinners";
import { isMobile } from "react-device-detect";
import { setUpConfigCat } from "../App/helper";
import {
  determineNextGame,
  fetchTestEventData,
  getTestIdsArray,
  getRevelianTestTitle,
  getRevelianTestType
} from "./helpers";
import { TestEventContext } from "../Contexts/TestEventContext";
import { GameData } from "../Interfaces/RevelianTestData";
import { navigate } from "@reach/router";
import { Container, Button, Row, Col, Alert } from "react-bootstrap";
import { apiURL } from "../utils/constants";
import RevelianTestEventCompleted from "./RevelianTestEventCompleted";
import LandingPageParameters from "../Interfaces/LandingPageParameters";
import LandingPageStyle from "../Interfaces/LandingPageStyle";
import TestHeader from "./TestDisplays/TestHeader";
import {
  getTranslatedTextWithURL,
  getReusedResultsCaseWithRawData,
  getReusedResultsCaseWithDecryptedData,
  getRedirectUrlForExitPage,
  SESSION_DISCONNECTED_TEXT
} from "../utils/shared";
import { revelianTestToSubTestIdMap } from "../utils/Reference/SubTestDataMaps";
import TestAlreadyCompleted from "../Components/TestAlreadyCompleted";
const FETCH_TEST_EVENT_DATA_FAILED = "fetchTestEventDataFailed";
const DETERMINE_NEXT_GAME_FAILED = "determineNextGameFailed";
const END_SUB_TEST_FAILED = "endSubTestFailed";
const END_TEST_EVENT_FAILED = "endTestEventFailed";

interface Props {
  path: string;
  eventId?: string;
  gameId?: string;
  updateTestIndex: (testIndex: number) => void;
  updateCompletedSubTestIds: (subTestId: string) => string[];
  fetchTestEventByEventId: (
    eventId: string,
    fromLink: boolean,
    fromExternalTest: boolean,
    isADSAssessment?: boolean
  ) => Promise<boolean>;
  generateLandingPageStyle: (
    landingPageParameters: LandingPageParameters
  ) => LandingPageStyle;
  updateExitPage: (returnUrl: string) => void;
  updateConfigCatObject: (configCatObject: any) => void;
  updateTestEventData: (testEventData: any) => void;
}

const RevelianTest = ({
  eventId,
  gameId,
  updateTestIndex,
  updateCompletedSubTestIds,
  fetchTestEventByEventId,
  generateLandingPageStyle,
  updateExitPage,
  updateConfigCatObject,
  updateTestEventData
}: Props) => {
  const context = useContext(TestEventContext);

  const [testIndex] = useState(0);
  const [testEventData, setTestEventData] = useState<any>();
  const [savedEventId, setSavedEventId] = useState("");
  const [subTestComplete, setSubTestComplete] = useState(false);
  const [showLoader, setShowLoader] = useState<null | boolean>(null);
  const [testEventComplete, setTestEventComplete] = useState(false);
  const [gameError, setGameError] = useState(false);
  const [testTitle, setTestTitle] = useState("");
  const [testType, setTestType] = useState("");
  const [subTestResubmitCount, setSubTestResubmitCount] = useState(0);
  const [failedRequestType, setFailedRequestType] = useState("");
  const [apiErrorMessage, setApiErrorMessage] = useState(""); // error message returned in api 400 response
  const [testCompletedCase, setTestCompletedCase] = useState<number>(0); // 0 - not completed, 1 - completed, 2 - reused, 3 - custom exit page

  const endTestEvent = useCallback(async testEventData => {
    if (!(testEventData && testEventData.token)) {
      return;
    }

    const testEventId = {
      testEventId: testEventData.testEventId
    };

    try {
      const response = await fetch(`${apiURL}/complete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: testEventData.token
        },
        body: JSON.stringify(testEventId)
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(
          `An error has occurred: ${response.status} ${
            data && data.error ? data.error : ""
          }`
        );
      }

      setFailedRequestType("");
      setShowLoader(false);
      setTestEventComplete(true);
      setSubTestComplete(true);
    } catch (error) {
      setShowLoader(false);
      setFailedRequestType(END_TEST_EVENT_FAILED);
    }
  }, []);

  const endSubTest = useCallback(
    async testEventData => {
      if (testEventData && testEventData.token) {
        const testArray = getTestIdsArray(testEventData.tests);
        const subTestData = {
          testEventId: testEventData.testEventId,
          subTestId: testArray[0],
          testTakerId: testEventData.testTaker.testTakerId
        };

        try {
          const response = await fetch(`${apiURL}/finishTest`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: testEventData.token
            },
            body: JSON.stringify(subTestData)
          });

          const data = await response.json();

          if (!response.ok) {
            throw new Error(
              `An error has occurred: ${response.status} ${
                data && data.error ? data.error : ""
              }`
            );
          }
          // reset the resubmit count after a successful resubmission
          setFailedRequestType("");

          // testArray.length equals 1 means that this is the only incomplete test remaining.
          const isLastTest = testArray.length === 1;

          const isVideoInterviewNext =
            testEventData.videoInterview &&
            testEventData.sortOrder[1] === "Video Interview";

          // if this is the last test and VI is next in event, redirect to overview
          if (isLastTest && isVideoInterviewNext && eventId) {
            await fetchTestEventByEventId(eventId, false, true);
            navigate("/overview");
            return;
          }

          // otherwise if VI is not next - either end test event or proceed
          if (isLastTest) {
            setUpConfigCat(
              testEventData,
              testEventData.testTaker.testTakerState,
              testEventData.locale === "AU",
              isMobile,
              updateConfigCatObject
            );
            endTestEvent(testEventData);
          } else {
            updateTestIndex(testIndex);
            setSubTestComplete(true);
            setShowLoader(false);
          }
        } catch (error) {
          setShowLoader(false);
          setFailedRequestType(END_SUB_TEST_FAILED);
        }
      }
      return true;
    },
    [
      endTestEvent,
      testIndex,
      updateTestIndex,
      eventId,
      fetchTestEventByEventId,
      updateConfigCatObject
    ]
  );

  const handleDetermineNextGame = useCallback(
    async (testEventData: any, eventId: string) => {
      const testArray = getTestIdsArray(testEventData.tests);
      const revelianTests = revelianTestToSubTestIdMap.filter(obj =>
        obj.subTestIds.includes(testArray[0])
      );

      // if `revelianTests` is an empty array, that means the current revelian test that the candidate is trying to end (testArray[0]) has already been marked as completed
      // this can happen in cases like if the candidate has reached the 'continue to the next test' page previously, but certain actions trigger the redirection flow again (ie. page refresh)
      // in that case, we want to re-surface the 'continue to the next test'
      if (testArray.length > 0 && revelianTests.length === 0) {
        updateTestIndex(testIndex);
        setSubTestComplete(true);
        setShowLoader(false);
        return;
      }

      try {
        const gameData = await determineNextGame(eventId);
        setFailedRequestType("");
        // once that API call is returned, create an array of the remaining games that still need
        // to be taken.
        const currentGameData = gameData.games.filter((game: any) =>
          game.url.includes(revelianTests[0].name)
        );
        const erroredGames = currentGameData.filter(
          (game: GameData) => game.gameStatus === "ERRORED"
        );

        if (erroredGames.length > 0) {
          setTestTitle(getRevelianTestTitle(testArray[0]));
          setTestType(getRevelianTestType(testArray[0]));
          setGameError(true);
          setShowLoader(false);
        } else {
          const remainingGames = currentGameData.filter(
            (game: GameData) =>
              game.gameStatus !== "COMPLETED" &&
              game.gameStatus !== "ERRORED" &&
              game.gameStatus !== "INVALIDATED"
          );
          // if that array is not empty, then navigate to the next game's url.
          if (remainingGames.length > 0) {
            // set the loader status to not show
            setShowLoader(false);

            // navigate to the next game url
            navigate(remainingGames[0].url);
          } else {
            // if that array is empty, that means all games have been completed, and we want to end the subTest
            endSubTest(testEventData);
          }
        }
      } catch (error) {
        setShowLoader(false);
        setFailedRequestType(DETERMINE_NEXT_GAME_FAILED);
      }
    },
    [endSubTest, testIndex, updateTestIndex]
  );

  const handleFetchTestEventData = useCallback(
    async (eventId: string) => {
      try {
        const data = await fetchTestEventData(eventId);
        if (!data) {
          throw new Error(
            "Network request failed while fetching test event data"
          );
        }

        // if api returns a response of error field with error message
        if (data.responseStatus !== 200) {
          if (data.translatedText) {
            setTestEventData(data);
          }

          if (data.responseStatus === 400 && data.error) {
            setFailedRequestType("");
            setShowLoader(false);

            const reusedResultsCase = getReusedResultsCaseWithRawData(data);

            if (reusedResultsCase > 0) {
              setTestCompletedCase(reusedResultsCase);
              return;
            }
            // else surface the error message
            setApiErrorMessage(data.error);
            return;
          } else {
            throw new Error(
              data.error || `api error code: ${data.responseStatus}`
            );
          }
        }

        setTestEventData(data);
        updateTestEventData(data);
        // some tests (eg. Illustrait) have a delay in scoring. to prevent the candidate from retaking the test, BE will mark it as completed first. but fetchTestEventData won't return an error yet
        const testArray = getTestIdsArray(data.tests);
        if (testArray.length === 0) {
          setFailedRequestType("");
          setShowLoader(false);

          const redirectUrl = getRedirectUrlForExitPage(data);
          if (redirectUrl) {
            updateExitPage(redirectUrl);
          }
          let reusedResultsCase = 0;
          if (data.hasDecryptedData) {
            reusedResultsCase = getReusedResultsCaseWithDecryptedData(data);
          } else {
            reusedResultsCase = getReusedResultsCaseWithRawData(data);
          }

          setTestCompletedCase(reusedResultsCase);
          return;
        }
        setFailedRequestType("");
        handleDetermineNextGame(data, eventId); // make a separate API call to get the status of all the games for the candidate
      } catch (error) {
        setShowLoader(false);
        setFailedRequestType(FETCH_TEST_EVENT_DATA_FAILED);
      }
    },
    [handleDetermineNextGame, updateExitPage, updateTestEventData]
  );

  useEffect(() => {
    if (eventId && showLoader === null) {
      setShowLoader(true);
      setSavedEventId(eventId);
      handleFetchTestEventData(eventId);
    }
  }, [eventId, showLoader, handleFetchTestEventData]);

  useEffect(() => {
    if (failedRequestType === "" && subTestResubmitCount > 0) {
      setSubTestResubmitCount(0);
    }
  }, [failedRequestType, subTestResubmitCount]);

  const handleContinue = () => {
    if (eventId) {
      fetchTestEventByEventId(eventId, false, true, true);
    }
  };

  const handleSubTestResubmit = () => {
    setShowLoader(true);
    setSubTestResubmitCount(prevState => prevState + 1);

    // request the endpoint again based on the fail type
    if (failedRequestType === FETCH_TEST_EVENT_DATA_FAILED && savedEventId) {
      handleFetchTestEventData(savedEventId);
    }

    if (
      failedRequestType === DETERMINE_NEXT_GAME_FAILED &&
      testEventData &&
      savedEventId
    ) {
      handleDetermineNextGame(testEventData, savedEventId);
    }

    if (failedRequestType === END_SUB_TEST_FAILED && testEventData) {
      endSubTest(testEventData);
    }

    if (failedRequestType === END_TEST_EVENT_FAILED && testEventData) {
      endTestEvent(testEventData);
    }
  };

  return (
    <div>
      {showLoader ? (
        <Row>
          <Col className="spinner-container">
            <div className="spinner text-center">
              <FadeLoader
                height={20}
                width={20}
                radius={20}
                margin={30}
                color="#B1B3B3"
              />
            </div>
          </Col>
        </Row>
      ) : null}
      {subTestComplete && !showLoader && !testEventComplete ? (
        <div className="text-center">
          <p>
            {testEventData && testEventData?.translatedText?.completedSubTest
              ? testEventData.translatedText.completedSubTest
              : "You have completed this part of the test."}
          </p>

          <Button variant="primary" onClick={handleContinue}>
            {testEventData && testEventData?.translatedText?.continueSubTest
              ? testEventData.translatedText.continueSubTest
              : "Continue to the next test"}
          </Button>
        </div>
      ) : null}
      {testCompletedCase === 1 ? <TestAlreadyCompleted /> : null}
      {testCompletedCase === 2 ? <TestAlreadyCompleted reusedResults /> : null}
      {testCompletedCase === 3 ||
      (subTestComplete && !showLoader && testEventComplete) ? (
        <RevelianTestEventCompleted
          testEventData={testEventData}
          generateLandingPageStyle={generateLandingPageStyle}
          numberOfSecondsToDelay={
            testEventData?.exitPage?.numberOfSecondsToDelay
              ? testEventData.exitPage.numberOfSecondsToDelay
              : 5
          }
        />
      ) : null}
      {apiErrorMessage !== "" ? (
        <Container>
          <Row>
            <Col
              xl={{ span: 8, offset: 2 }}
              lg={{ span: 8, offset: 2 }}
              md={{ span: 10, offset: 1 }}
              sm={12}
            >
              <Alert variant="danger" className="text-center">
                <div dangerouslySetInnerHTML={{ __html: apiErrorMessage }} />
              </Alert>
            </Col>
          </Row>
        </Container>
      ) : null}
      {gameError ? (
        <Container>
          <Row>
            <Col
              xl={{ span: 8, offset: 2 }}
              lg={{ span: 8, offset: 2 }}
              md={{ span: 10, offset: 1 }}
              sm={12}
            >
              <TestHeader testTitle={testTitle} testType={testType} />
              <div id="instructions-text">
                <h3>Error!</h3>
                <p>{getTranslatedTextWithURL(context, "warningText3")}</p>
              </div>
            </Col>
          </Row>
        </Container>
      ) : null}
      <div className="text-center">
        {failedRequestType !== "" && !showLoader ? (
          <React.Fragment>
            <h4>
              {testEventData?.translatedText?.testSendFail
                ? testEventData.translatedText.testSendFail
                : "There was a problem submitting your results."}
            </h4>
            <Button variant="primary" onClick={handleSubTestResubmit}>
              {testEventData?.translatedText?.resubmitTest
                ? testEventData.translatedText.resubmitTest
                : "Resubmit test results"}
            </Button>
            {subTestResubmitCount > 0 ? (
              <p style={{ paddingTop: "1rem" }}>
                {testEventData?.translatedText?.contactSupportFail
                  ? testEventData.translatedText.contactSupportFail
                  : SESSION_DISCONNECTED_TEXT}
              </p>
            ) : null}
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};

export default RevelianTest;
