import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { TestEventContext } from "../Contexts/TestEventContext";
import { getTranslationLanguage, translate } from "../utils/translationHelpers";
import { isODAFooterDisabled } from "../utils/customConfig";

const Footer = () => {
  const context = useContext(TestEventContext);

  const [language, setLanguage] = useState("");

  useEffect(() => {
    if (context && context.testEventData) {
      setLanguage(getTranslationLanguage(context.testEventData, context.isAU));
    }
  }, [context]);

  const isArabic =
    context && context?.testEventData?.translatedText?.locale
      ? context?.testEventData?.translatedText?.locale === "AR"
      : null;

  const linkNames = ["copyrightNotices", "terms", "privacy", "accommodations"];

  const FooterLinks = linkNames.map(linkName => (
    <a
      key={linkName}
      href={translate("common", `${linkName}Link`, language)}
      target="_blank"
      rel="noopener noreferrer"
      className={isArabic ? "text-right" : ""}
      dir={isArabic ? "rtl" : "ltr"}
    >
      {translate("common", linkName, language)}
    </a>
  ));

  if (isODAFooterDisabled()) {
    return <></>;
  }

  return (
    <Row className="footer">
      <Col
        xl={{ span: 8, offset: 2 }}
        lg={{ span: 8, offset: 2 }}
        md={{ span: 10, offset: 1 }}
        sm={12}
        className="text-center"
      >
        <div>
          <p className="mb-0">
            {isArabic ? (
              <span dir={isArabic ? "rtl" : "ltr"}>
                حقوق الطبع والنشر محفوظة © {new Date().getFullYear()} لشركة
                Criteria Corp.{" "}
              </span>
            ) : (
              `${translate(
                "common",
                "copyright",
                language
              )} © ${new Date().getFullYear()} Criteria Corp. `
            )}
            | {FooterLinks[0]}
          </p>
          <p className="mt-0">
            {FooterLinks[1]}
            {" | "}
            {FooterLinks[2]}
            {" | "}
            {FooterLinks[3]}
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default Footer;
